import { mapGetters, mapActions } from 'vuex'
import { clone } from 'ramda'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'

export default {
  name: 'BreadcrumbComponent',
  components: {
    AutocompleteComponent
  },
  props: {
    /**
     * tab : {
     *  id: <identificador único del tab>,
     *  name: <nombre del tab>
     * }
     */
    tabs: {
      type: Array,
      required: true
    },
    tabSelected: {
      type: Function,
      required: true
    },
    buttons: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },
  data () {
    return {
      selectedEntity: undefined
    }
  },
  mounted () {

    const search = new URLSearchParams(window.location.search)

    const selectedtab = search.get('tab')

    if (selectedtab) {
      this.tabSelected(this.tabs[selectedtab - 1].id)
    }
  },
  computed: {
    ...mapGetters({
      getEntitySelectorItems: 'breadcrumb/getEntitySelectorItems',
      getSelectedItem: 'breadcrumb/getSelectedItem',
      getEntitySelectorConfiguration: 'breadcrumb/getEntitySelectorConfiguration',
      getDetails: 'breadcrumb/getDetails'
    }),
    /**
     * Ordenar los ítems del selector
     * @returns
     */
    entitySelectorItemsSorted () {
      const selectorItemsCopy = clone(this.getEntitySelectorItems)
      const that = this
      return selectorItemsCopy.sort(function sortFunction (a, b) {
        const nameA = a[that.getEntitySelectorConfiguration.text].toLowerCase()
        const nameB = b[that.getEntitySelectorConfiguration.text].toLowerCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      })
    }
  },
  created () {
    this.unwatch = this.$store.watch(state => state.breadcrumb.selectedItem, () => {
      if (this.$store.state.breadcrumb.selectedItem) {
        this.selectedEntity = this.$store.state.breadcrumb.selectedItem
      } else if (this.$store.state.breadcrumb.entitySelectorItems && this.$store.state.breadcrumb.entitySelectorItems.length > 0) {
        this.selectedEntity = this.$store.state.breadcrumb.entitySelectorItems[0]
      }
    })
  },
  beforeDestroy () {
    this.unwatch()
  },
  methods: {
    ...mapActions('breadcrumb', [
      'setSelectedItem'
    ]),
    selectedFunction (newValue) {
      this.selectedEntity = newValue && newValue[this.getEntitySelectorConfiguration.value] ? newValue : this.selectedEntity
      this.$emit('select', this.selectedEntity)
    }
  },
  watch: {
    selectedEntity () {
      this.setSelectedItem(this.selectedEntity)
    }
  }
}
