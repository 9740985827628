import store from "@/store/store";
import i18n from "@/i18n";
import { clone } from "ramda";
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib';
import { getDateTimeRange } from "../tools/functions";
import { unitParser } from '@colven/common-domain-lib/lib';
export const breadcrumbService = (function () {
    "use strict";

    function setSummaryDetailsByFilters(filters, reportType) {
        const details = getFilterDetailsHTML(filters, reportType);
        store.dispatch("breadcrumb/setDetails", details);
    }

    function getFilterDetailsHTMLByThing(filters, thingName) {
        const newFilters = clone(filters);
        if (!newFilters.generalData) {
            newFilters.generalData = {};
        }
        newFilters.generalData.selectedThings = [];
        newFilters.generalData.selectedThings.push({
            enterpriseId: 1,
            name: thingName
        });
        return this.getFilterDetailsHTML(newFilters);
    }

    function getFilterDetailsHTML(filters, reportType) {      
        let details = '';
        if(reportType){
            details += `<b>${i18n.t('breadcrumb.details.reportType.' + reportType)}</b><hr>`
        }
        if (filters) {
            if (
                filters.generalData &&
                filters.generalData.selectedThings &&
                filters.generalData.selectedThings.length > 0
            ) {
                details += `<b>${i18n.t('breadcrumb.details.things')}: </b>`
                const names = getThingNames(filters.generalData.selectedThings);
                for (const name of names) {
                    details += `${name},`
                }
                details += '<br>'
            }
            if (
                filters.generalData &&
                filters.generalData.serviceType &&
                filters.generalData.serviceType.length > 0
            ) {
                details += `<b>${i18n.t('breadcrumb.details.serviceTypes')}: </b>`
                const serviceTypes = filters.generalData.serviceType.map(
                    (serviceType) => serviceType.key
                );
                for (const serviceType of serviceTypes) {
                    details += `${i18n.t('serviceType.' + serviceType)}`
                }
                details += '<br>'
            }
            if (filters.dateData) {
                details += `<b>${i18n.t('breadcrumb.details.reportPeriod')}: </b>`
                const reportFixedRange = filters.dateData.selectedDateAndTimeRange;
                details += `${i18n.t('reportFixedRange.' + reportFixedRange)}`
                details += '<br>'
                if (reportFixedRange === TimeRanges.CUSTOM) {
                    const sinceDateTimeStamp = stringToDate(filters.dateData.sinceDate, "yyyy-MM-dd", "-").getTime()/1000;
                    const sinceDate = unitParser.parseDate(sinceDateTimeStamp,store.getters['user/getInternationalization'].dateMask);
                    const toDateDateTimeStamp = stringToDate(filters.dateData.toDate, "yyyy-MM-dd", "-").getTime()/1000;        
                    const toDate = unitParser.parseDate(toDateDateTimeStamp,store.getters['user/getInternationalization'].dateMask);
                    if (filters.dateData.selectedDateAndTimeCustomRangeType === CustomTimeRangeTypes.DATE) {
                        details += `<b>${i18n.t('breadcrumb.details.from')}: </b>`;
                        details += `${sinceDate}`;
                        details += "<br>";
                        details += `<b>${i18n.t('breadcrumb.details.to')}: </b>`;
                        details += `${toDate}`;
                        details += '<br>';
                    } else {
                        details += `<b>${i18n.t('breadcrumb.details.from')}: </b>`;
                        details += `${sinceDate} ${unitParser.parseTime(filters.dateData.sinceTime,store.getters['user/getInternationalization'].date)}`;
                        details += '<br>';
                        details += `<b>${i18n.t('breadcrumb.details.to')}: </b>`;
                        details += `${toDate} ${unitParser.parseTime(filters.dateData.toTime,store.getters['user/getInternationalization'].date)}`;
                        details += '<br>';
                    }
                }
            }
            if (filters.configurationsData && filters.configurationsData.selectedYieldUnit && reportType === "PERFORMANCE") {
                details += `<b>${i18n.t('breadcrumb.details.yieldUnit')}: </b>`
                const yieldUnit = filters.configurationsData.selectedYieldUnit;
                details += `${i18n.t('yieldUnits.' + yieldUnit)}`
                details += '<br>'
            }
            if (filters.orderData) {
                details += `<b>${i18n.t('breadcrumb.details.sortType')}: </b>`
                const sortType = filters.orderData.selectedSortOption;
                const iprop = unitParser.changeUnit(i18n.t("dischargeSummary.filterProperties.worstBestProperty"), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, (text) => {return i18n.t(text)})
                details += `${i18n.t('sortTypes.' + sortType, { propName: iprop })}`
                details += '<br>'
            }
            if (filters.rangeData) {
                if (filters.rangeData.greaterThan || filters.rangeData.greaterThan === 0) {
                    details += `<b>${i18n.t('breadcrumb.details.rangeGreatThan')} </b> ${filters.rangeData.greaterThan}`
                    details += '<br>'
                }
                if (filters.rangeData.lessThan || filters.rangeData.lessThan === 0) {
                    details += `<b>${i18n.t('breadcrumb.details.rangeLessThan')} </b> ${filters.rangeData.lessThan}`
                    details += '<br>'
                }
            }
        }
        return details;
    }
    function stringToDate(_date,_format,_delimiter)
    {
                var formatLowerCase=_format.toLowerCase();
                var formatItems=formatLowerCase.split(_delimiter);
                var dateItems=_date.split(_delimiter);
                var monthIndex=formatItems.indexOf("mm");
                var dayIndex=formatItems.indexOf("dd");
                var yearIndex=formatItems.indexOf("yyyy");
                var month=parseInt(dateItems[monthIndex]);
                month-=1;
                var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
                return formatedDate;
    }
    function getThingNames(groups) {
        let result = [];
        for (const group of groups) {
            if (group.enterpriseId) {
                result.push(group.name);
            }
            if (group.children) {
                result = result.concat(getThingNames(group.children));
            }
        }
        return result;
    }

    

    function getFilterDetailsHTMLExport(filters, reportType = null, thingName = null){        
        let details = '';
        if(reportType){
            details += `<b>${i18n.t('breadcrumb.details.reportType.' + reportType)}</b><hr>`
        }
        if (thingName) {
            details += `<b>${i18n.t('breadcrumb.details.things')}: ${thingName}</b><br>`;
        } else if (
                filters.generalData &&
                filters.generalData.selectedThings &&
                filters.generalData.selectedThings.length > 0) {
                details += `<b>${i18n.t('breadcrumb.details.things')}: </b>`;
                const names = getThingNames(filters.generalData.selectedThings);
                for (const name of names) {
                    details += `${name},`;
                }
                details += '<br>';
        }
        if (
            filters.generalData &&
            filters.generalData.serviceType &&
            filters.generalData.serviceType.length > 0
        ) {
            details += `<b>${i18n.t('breadcrumb.details.serviceTypes')}: </b>`
            const serviceTypes = filters.generalData.serviceType.map(
                (serviceType) => serviceType.key
            );
            for (const serviceType of serviceTypes) {
                details += `${i18n.t('serviceType.' + serviceType)}`
            }
            details += '<br>'
        }
        if (filters.dateData) {
            const reportFixedRange = filters.dateData.selectedDateAndTimeRange;
            if (reportFixedRange === TimeRanges.CUSTOM) {
                const sinceDate = unitParser.parseDate(filters.dateData.sinceDate,store.getters['user/getInternationalization'].dateMask);
                console.log(sinceDate);
                const toDate = unitParser.parseDate(filters.dateData.toDate ,store.getters['user/getInternationalization'].dateMask);
                if (filters.dateData.selectedDateAndTimeCustomRangeType === CustomTimeRangeTypes.DATE) {
                    details += `<b>${i18n.t('breadcrumb.details.from')}: </b>`;
                    details += `${sinceDate}`;
                    details += "<br>";
                    details += `<b>${i18n.t('breadcrumb.details.to')}: </b>`;
                    details += `${toDate}`;
                    details += '<br>';
                } else {
                    details += `<b>${i18n.t('breadcrumb.details.from')}: </b>`;
                    details += `${sinceDate} ${unitParser.parseTime(filters.dateData.sinceTime,store.getters['user/getInternationalization'].date)}`;
                    details += '<br>';
                    details += `<b>${i18n.t('breadcrumb.details.to')}: </b>`;
                    details += `${toDate} ${unitParser.parseTime(filters.dateData.toTime,store.getters['user/getInternationalization'].date)}`;
                    details += '<br>';
                }
            } else {
                const {tFrom, tTo} = getDateTimeRange(reportFixedRange, null, null, null, null, null);
                details += `<b>${i18n.t('breadcrumb.details.from')}: </b>`;
                details += `${unitParser.parseDateAndTime(tFrom, store.getters['user/getInternationalization'].dateMask, store.getters['user/getInternationalization'].date )} - `;
                details += `<b>${i18n.t('breadcrumb.details.to')}: </b>`;
                details += `${unitParser.parseDateAndTime(tTo, store.getters['user/getInternationalization'].dateMask, store.getters['user/getInternationalization'].date )}`;
                details += '<br>';
            }
        }
        if (filters.configurationsData && filters.configurationsData.selectedYieldUnit && reportType === "PERFORMANCE") {
            details += `<b>${i18n.t('breadcrumb.details.yieldUnit')}: </b>`
            const yieldUnit = filters.configurationsData.selectedYieldUnit;
            details += `${i18n.t('yieldUnits.' + yieldUnit)}`
            details += '<br>'
        }
        if (filters.orderData) {
            details += `<b>${i18n.t('breadcrumb.details.sortType')}: </b>`
            const sortType = filters.orderData.selectedSortOption;
            const iprop = unitParser.changeUnit(i18n.t("dischargeSummary.filterProperties.worstBestProperty"), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, (text) => {return i18n.t(text)})
                
            details += `${i18n.t('sortTypes.' + sortType, { propName: iprop })}`
            details += '<br>'
        }
        if (filters.rangeData) {
            if (filters.rangeData.greaterThan || filters.rangeData.greaterThan === 0) {
                details += `<b>${i18n.t('breadcrumb.details.rangeGreatThan')} </b> ${filters.rangeData.greaterThan}`
            }
            if (filters.rangeData.lessThan || filters.rangeData.lessThan === 0) {
                details += ` - <b>${i18n.t('breadcrumb.details.rangeLessThan')} </b> ${filters.rangeData.lessThan}`
                details += '<br>'
            }
        }

        return details;
    }

    return {
        setSummaryDetailsByFilters,
        getFilterDetailsHTML,
        getFilterDetailsHTMLByThing,
        getFilterDetailsHTMLExport
    }
})();